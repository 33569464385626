exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-us-contact-us-tsx": () => import("./../../../src/templates/contactUs/ContactUs.tsx" /* webpackChunkName: "component---src-templates-contact-us-contact-us-tsx" */),
  "component---src-templates-faq-faq-tsx": () => import("./../../../src/templates/faq/FAQ.tsx" /* webpackChunkName: "component---src-templates-faq-faq-tsx" */),
  "component---src-templates-home-home-tsx": () => import("./../../../src/templates/home/Home.tsx" /* webpackChunkName: "component---src-templates-home-home-tsx" */),
  "component---src-templates-landing-page-campaign-wizard-success-page-tsx": () => import("./../../../src/templates/landingPage/CampaignWizardSuccessPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-campaign-wizard-success-page-tsx" */),
  "component---src-templates-landing-page-landing-page-tsx": () => import("./../../../src/templates/landingPage/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-landing-page-tsx" */),
  "component---src-templates-message-message-tsx": () => import("./../../../src/templates/message/Message.tsx" /* webpackChunkName: "component---src-templates-message-message-tsx" */),
  "component---src-templates-sitemap-sitemap-tsx": () => import("./../../../src/templates/sitemap/Sitemap.tsx" /* webpackChunkName: "component---src-templates-sitemap-sitemap-tsx" */),
  "component---src-templates-subject-subject-tsx": () => import("./../../../src/templates/subject/Subject.tsx" /* webpackChunkName: "component---src-templates-subject-subject-tsx" */),
  "component---src-templates-sustainability-s-12-y-category-page-tsx": () => import("./../../../src/templates/sustainability/S12yCategoryPage.tsx" /* webpackChunkName: "component---src-templates-sustainability-s-12-y-category-page-tsx" */),
  "component---src-templates-sustainability-s-12-y-landing-page-tsx": () => import("./../../../src/templates/sustainability/S12yLandingPage.tsx" /* webpackChunkName: "component---src-templates-sustainability-s-12-y-landing-page-tsx" */),
  "component---src-templates-sustainability-via-se-infographic-1-tsx": () => import("./../../../src/templates/sustainability/via.se/Infographic1.tsx" /* webpackChunkName: "component---src-templates-sustainability-via-se-infographic-1-tsx" */),
  "component---src-templates-sustainability-via-se-infographic-2-tsx": () => import("./../../../src/templates/sustainability/via.se/Infographic2.tsx" /* webpackChunkName: "component---src-templates-sustainability-via-se-infographic-2-tsx" */),
  "component---src-templates-sustainability-via-se-infographic-3-tsx": () => import("./../../../src/templates/sustainability/via.se/Infographic3.tsx" /* webpackChunkName: "component---src-templates-sustainability-via-se-infographic-3-tsx" */),
  "component---src-templates-variant-variant-tsx": () => import("./../../../src/templates/variant/Variant.tsx" /* webpackChunkName: "component---src-templates-variant-variant-tsx" */)
}

